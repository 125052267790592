const { publicRuntimeConfig } = require('next/config').default();
const { ADMIN, ANPOST, BNPP, TOKEN } = require('../constants/banks');
const { TYPE_UNDETERMINED } = require('../constants/memberTypes');
const { BANK_ADMIN } = require('../constants/roles');
const { REFUND, VIRTUAL_ACCOUNT } = require('../constants/memberPermissions');
const { UNDER_RESELLER, SUB_TPP } = require('../constants/clientType');

const TYPE_ONE = 'TYPE_ONE';
const REFUNDS = 'refund';
const CERTIFICATE_MANAGEMENT = 'certificateManagement';

function _findLink(linkList, currLink) {
    for (const link in linkList) {
        if (linkList[link].link === currLink) {
            return linkList[link];
        }

        if (linkList[link].subLinks) {
            const subLink = _findLink(linkList[link].subLinks, currLink);

            if (subLink) {
                return subLink;
            }
        }
    }
}

function _findAndUpdateLink(linkList, currLink) {
    const linkOnly = currLink.split('?')[0];
    const link = _findLink(linkList, linkOnly);

    if (link) {
        link['selected'] = true;
        return true;
    }

    return false;
}

const getNestedLink = (route = '/', scope = TOKEN, member, user, client) => {
    const splitRoutes = route.split('/');
    const baseRoute = `/${splitRoutes[1]}`;
    const newNestedLinkList = getBaseNestedLinks(scope, member, user, client);

    _findAndUpdateLink(newNestedLinkList, baseRoute);

    // If a child link is selected, then a Parent link must be selected
    const certificateManagementNestedSubLinks = newNestedLinkList.certificateManagement['subLinks'];
    newNestedLinkList.certificateManagement['selected'] =
        certificateManagementNestedSubLinks.bankConnections['selected'] ||
        certificateManagementNestedSubLinks.refund['selected'];

    return newNestedLinkList;
};

const getLinks = (route = '/', scope = TOKEN, member, user, client) => {
    const splitRoutes = route.split('/');
    let baseRoute = `/${splitRoutes[1]}`;
    const newLinkList = getBaseLinks(scope, member, user, client);

    const hasMatch = _findAndUpdateLink(newLinkList, baseRoute);

    // If a parent link isn't selected, then a child link must be selected
    newLinkList.settings['selected'] = !hasMatch;

    if (splitRoutes[2]) {
        baseRoute = `/${splitRoutes[1]}/${splitRoutes[2]}`;
        _findAndUpdateLink(newLinkList, baseRoute);
    }

    return newLinkList;
};

const unAuthorizedPath = (props) => {
    let routeOnly = '';
    let isUnAuthorized = false;
    const { route, client, displaySettings } = props;
    if (route) {
        routeOnly = route.split('?')[0];
        routeOnly = routeOnly.split('/')[1];
        const routePath = `/${routeOnly}`;
        const linkList = getLinks(routePath);
        Object.keys(linkList).map((link) => {
            if (
                linkList[link].link === routePath &&
                !linkList[link].clientType.includes(client.clientType)
            ) {
                isUnAuthorized = true;
            }
        });
    }
    if (
        route === '/beneficiary' &&
        [SUB_TPP, UNDER_RESELLER].includes(client.clientType) &&
        !displaySettings['beneficiarySettings']
    ) {
        //Applicable to only UNDER_RESELLER and SUB_TPP clients
        isUnAuthorized = true;
    }
    return isUnAuthorized;
};

const isPathDisabledForUser = (props, scope) => {
    const { user, member, client, route } = props;
    let routeOnly = '';
    if (route) {
        routeOnly = route.split('?')[0];
    }
    const link =
        _findLink(getBaseLinks(scope, member, user, client), routeOnly) ||
        _findLink(getBaseNestedLinks(scope, member, user, client), routeOnly) ||
        _findLink(getPublicLinks(scope), routeOnly);

    return link && link.disabled;
};

const isPathDisabled = (host, route) => {
    // Don't check Next.js files
    if (route.startsWith('/_next/')) {
        return false;
    }

    const { getScope } = require('../scopes');
    const scope = getScope(host);
    const routeOnly = route.split('?')[0];

    const parentRoute = `/${route.split('/')[1]}`;
    const adminLinks = Object.values(
        publicRuntimeConfig.links.admin,
    ).flatMap(({ link, subLinks }) =>
        link ? [link] : Object.values(subLinks).map((sublink) => sublink.link),
    );

    if (scope !== ADMIN && adminLinks.includes(parentRoute)) {
        return true;
    }

    const link =
        _findLink(getBaseLinks(scope), routeOnly) ||
        _findLink(getBaseNestedLinks(scope), routeOnly) ||
        _findLink(getPublicLinks(scope), routeOnly);

    return link && link.disabled;
};

const getPublicLinks = (scope) => {
    const newLinkList = JSON.parse(JSON.stringify(publicRuntimeConfig.links.public));

    switch (scope) {
        case TOKEN:
            newLinkList.signup.subLinks.express['disabled'] = true;
            break;
        default:
            break;
    }

    return newLinkList;
};

const getBaseLinks = (scope, member, user, client) => {
    const newLinkList = getFilteredLinks(publicRuntimeConfig.links.parent, member, user, client);

    switch (scope) {
        case TOKEN:
        case BNPP:
            break;
        default:
            newLinkList.bankConnections['disabled'] = true;
            break;
    }

    if (member && newLinkList['virtualAccounts'] && !newLinkList.virtualAccounts['disabled']) {
        const hasNoPermission = !member.permissions.includes(VIRTUAL_ACCOUNT);
        const parentNotAllowed = member.vaUnregulated;
        const isSubTpp = !!member.subTppId;
        newLinkList.virtualAccounts['disabled'] = hasNoPermission || (parentNotAllowed && !isSubTpp);
    }

    return newLinkList;
};

const getBaseNestedLinks = (scope, member, user, client) => {
    const newLinkList = getFilteredLinks(publicRuntimeConfig.links.child, member, user, client);

    switch (scope) {
        case TOKEN:
        case ANPOST:
            break;
        case BNPP:
            newLinkList.beneficiary['disabled'] = true;
            break;
        default:
            newLinkList.billing['disabled'] = true;
            newLinkList.beneficiary['disabled'] = true;
            newLinkList.certificateManagement['disabled'] = user && user.role === BANK_ADMIN;
            break;
    }
    if (member) {
        if (
            publicRuntimeConfig.hsbcMemberIds.includes(member.memberId) &&
            client?.clientType !== SUB_TPP
        ) {
            newLinkList.billing['disabled'] = false;
        }
        newLinkList.certificateManagement['link'] = '';
        // Disable Refund link if member does not have Refund permission.
        newLinkList.certificateManagement.subLinks.refund.disabled = !Object.values(
            member.permissions,
        ).includes(REFUND);

        newLinkList.bankConfiguration.disabled =
            newLinkList.certificateManagement.subLinks.bankConnections.disabled;
        newLinkList.bankRegistration.disabled =
            newLinkList.certificateManagement.subLinks.bankConnections.disabled;

        // Disable CERTIFICATE_MANAGEMENT if all its SubLinks are not visible
        // Check if the certificate management has any subLinks
        const certificateSubLinks = newLinkList[CERTIFICATE_MANAGEMENT].subLinks;

        const filteredSubLinks =
            certificateSubLinks &&
            Object.keys(certificateSubLinks).filter((key) => {
                const subLink = certificateSubLinks[key];
                const isRefundLink = key === REFUNDS;
                const isRefundPermission = member.permissions.includes(REFUND);
                const isNotTypeOneMember = member.memberType !== TYPE_ONE;

                // Conditions to include the subLink in the filtered list
                const isVisibleAndEnabled = !subLink.disabled && !subLink.hidden;
                const shouldInclude =
                    (isVisibleAndEnabled && isRefundPermission) ||
                    (!isRefundLink && isNotTypeOneMember);

                return shouldInclude;
            });

        // If no subLinks meet the conditions, disable certificate management
        if (filteredSubLinks.length === 0) {
            newLinkList[CERTIFICATE_MANAGEMENT].disabled = true;
        }
    }

    return newLinkList;
};

const getFilteredLinks = (links, member, user, client) => {
    const newLinkList = JSON.parse(JSON.stringify(links));

    if (!member || !user || !client) {
        return newLinkList;
    }

    Object.keys(newLinkList).forEach((key) => {
        const link = newLinkList[key];
        link.disabled = isLinkDisabled(link, member, user, client);

        if (link.subLinks) {
            Object.keys(link.subLinks).forEach((k) => {
                const subLink = link.subLinks[k];
                subLink.disabled = link.disabled || isLinkDisabled(subLink, member, user, client);
            });
        }
        return link;
    });

    return newLinkList;
};

const isLinkDisabled = (link, member, user, client) => {
    if (
        link.disabled ||
        (link.memberType &&
            member.memberType !== TYPE_UNDETERMINED &&
            !link.memberType.includes(member.memberType)) ||
        (link.userRole && !link.userRole.includes(user.role)) ||
        (link.clientType && !link.clientType.includes(client.clientType))
    ) {
        return true;
    }
    return false;
};

module.exports = {
    getLinks,
    getNestedLink,
    unAuthorizedPath,
    isPathDisabled,
    isPathDisabledForUser,
    _LINKS: publicRuntimeConfig.links.parent,
    _NESTED_LINKS: publicRuntimeConfig.links.child,
};
